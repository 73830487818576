var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pa-0 mx-0" },
    [
      _vm._l(_vm.aliasFieldsList, function (item, index) {
        return [
          !item.isPanelField &&
          (item.conditionscript && item.isShowElement
            ? item.isShowElement
              ? item.isShowElement(_vm.fields, _vm.self)
              : function () {}
            : item.isShow)
            ? _c(
                "v-col",
                {
                  key: index,
                  staticClass: "pa-1",
                  attrs: {
                    cols: _vm.$vuetify.breakpoint.mdAndDown
                      ? _vm.$vuetify.breakpoint.xsOnly ||
                        [10, 11, 12, 14, 16, 18].includes(item.type)
                        ? "12"
                        : "6"
                      : item.columns && item.type !== 16
                      ? item.columns
                      : "12",
                    md:
                      item.columns && item.type !== 16
                        ? item.columns
                        : [10, 11, 12, 14, 16, 18].includes(item.type)
                        ? "12"
                        : "6",
                  },
                },
                [
                  item.type === 1
                    ? [
                        _c("v-text-field", {
                          attrs: {
                            id: "_" + item.name,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            label: item.label,
                            rules: _vm.getValidation(item.validation) || [],
                            placeholder: item.placeholder,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.checkForValidationOrFunction(
                                item,
                                _vm.fields[item.name],
                                index
                              )
                            },
                          },
                          model: {
                            value: _vm.fields[item.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, item.name, $$v)
                            },
                            expression: "fields[item.name]",
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.type === 2
                    ? [
                        _c("label", [
                          _vm._v(_vm._s(item.label ? item.label : "")),
                        ]),
                        _c("v-textarea", {
                          attrs: {
                            id: "_" + item.name,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            label: item.label,
                            rules: _vm.getValidation(item.validation) || [],
                            placeholder: item.placeholder,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.checkForValidationOrFunction(
                                item,
                                _vm.fields[item.name],
                                index
                              )
                            },
                          },
                          model: {
                            value: _vm.fields[item.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, item.name, $$v)
                            },
                            expression: "fields[item.name]",
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.type === 3
                    ? [
                        _c(
                          "v-row",
                          [
                            item.label
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(item.label) + ":"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        item.default_value.viewType === "horizontal"
                          ? _c(
                              "v-row",
                              { staticClass: "mx-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 scroll-styles",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "d-flex flex-row",
                                        attrs: { flat: "", tile: "" },
                                      },
                                      _vm._l(
                                        _vm.getActiveOptions(
                                          item.default_value
                                        ),
                                        function (options, index) {
                                          return _c(
                                            "v-card",
                                            {
                                              key: index,
                                              attrs: {
                                                id: "datagrid-container",
                                                tile: "",
                                                flat: "",
                                                width:
                                                  _vm.getCheckboxLabelWidth(
                                                    options
                                                  ) + "px",
                                                "min-height": "60px",
                                                "min-width":
                                                  _vm.getCheckboxLabelWidth(
                                                    options
                                                  ) + "px",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mx-0",
                                                  attrs: { "no-gutters": "" },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass:
                                                      "checkbox_position",
                                                    attrs: {
                                                      "hide-details": "",
                                                      rules:
                                                        _vm.getValidation(
                                                          item.validation,
                                                          "multiple"
                                                        ) || [],
                                                      label: options.label,
                                                      value: options.value,
                                                      multiple: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkForValidationOrFunction(
                                                          item,
                                                          _vm.fields[item.name],
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields[item.name],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          item.name,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields[item.name]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-layout",
                              {
                                staticClass: "mx-0",
                                style:
                                  "" +
                                  (item.default_value.viewType === "vertical"
                                    ? "display:block"
                                    : ""),
                                attrs: { row: "", wrap: "" },
                              },
                              _vm._l(
                                _vm.getActiveOptions(item.default_value),
                                function (options, index) {
                                  return _c(
                                    "v-flex",
                                    { key: index, class: _vm.xs12 },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkbox_position",
                                        attrs: {
                                          "hide-details": "",
                                          rules:
                                            _vm.getValidation(
                                              item.validation,
                                              "multiple"
                                            ) || [],
                                          label: options.label,
                                          value: options.value,
                                          multiple: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkForValidationOrFunction(
                                              item,
                                              _vm.fields[item.name],
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.fields[item.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, item.name, $$v)
                                          },
                                          expression: "fields[item.name]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                      ]
                    : _vm._e(),
                  item.type === 4
                    ? [
                        _c("label", [_vm._v(_vm._s(item.label))]),
                        _c(
                          "v-radio-group",
                          {
                            attrs: {
                              dense: "",
                              row: "",
                              "hide-details": "",
                              rules: _vm.getValidation(item.validation) || [],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.checkForValidationOrFunction(
                                  item,
                                  _vm.fields[item.name],
                                  index
                                )
                              },
                            },
                            model: {
                              value: _vm.fields[item.name],
                              callback: function ($$v) {
                                _vm.$set(_vm.fields, item.name, $$v)
                              },
                              expression: "fields[item.name]",
                            },
                          },
                          _vm._l(
                            item.default_value.options,
                            function (options, index) {
                              return _c("v-radio", {
                                key: index,
                                attrs: {
                                  label: options.label,
                                  value: options.value,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRadioButtonClick(
                                      item,
                                      options,
                                      _vm.fields[item.name]
                                    )
                                  },
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 5
                    ? [
                        item.default_value.isAPI
                          ? [
                              _c("v-autocomplete", {
                                key: _vm.autoCompleteRender,
                                ref: "searchInput" + index,
                                refInFor: true,
                                attrs: {
                                  dense: "",
                                  id: "_" + item.name,
                                  outlined: "",
                                  items: item.default_value.options,
                                  label: item.label,
                                  rules:
                                    _vm.getValidation(item.validation) || [],
                                  autocomplete: "off",
                                  placeholder: item.placeholder,
                                  clearable: "",
                                  "hide-details": "",
                                  "item-text": item.default_value.name,
                                  "item-value": item.default_value.name,
                                },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.getValuesFromAPI(
                                      "searchInput" + index,
                                      item.default_value,
                                      index
                                    )
                                  },
                                  change: function ($event) {
                                    return _vm.checkForValidationOrFunction(
                                      item,
                                      _vm.fields[item.name],
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.fields[item.name],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fields, item.name, $$v)
                                  },
                                  expression: "fields[item.name]",
                                },
                              }),
                            ]
                          : (
                              item.default_value.selectType !== undefined
                                ? item.default_value.selectType === "default"
                                : true
                            )
                          ? [
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  id: "_" + item.name,
                                  outlined: "",
                                  items: _vm.getActiveOptions(
                                    item.default_value
                                  ),
                                  rules: item.validation
                                    ? _vm.$_multiSelectValidation
                                    : [],
                                  label: item.label,
                                  multiple: item.default_value.is_multiselect,
                                  placeholder: item.placeholder,
                                  clearable: "",
                                  "hide-details": "",
                                  "item-text": "label",
                                  "item-value": "value",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkForValidationOrFunction(
                                      item,
                                      _vm.fields[item.name],
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.fields[item.name],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fields, item.name, $$v)
                                  },
                                  expression: "fields[item.name]",
                                },
                              }),
                            ]
                          : item.default_value.selectType === "custom"
                          ? [
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  "item-text": item.optionName,
                                  "item-value": item.optionValue,
                                  items: item.customOptions,
                                  multiple: item.default_value.is_multiselect,
                                  placeholder: item.placeholder,
                                  clearable: "",
                                  label: _vm.$t(item.label),
                                  id: "_" + item.name,
                                  attach: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkForValidationOrFunction(
                                      item,
                                      _vm.fields[item.name],
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.fields[item.name],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fields, item.name, $$v)
                                  },
                                  expression: "fields[item.name]",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  item.type === 6
                    ? [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "max-width": "290",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              id: "_" + item.name,
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "",
                                              placeholder: item.placeholder,
                                              rules:
                                                _vm.getValidation(
                                                  item.validation
                                                ) || [],
                                              clearable: "",
                                              "prepend-icon": "mdi-calendar",
                                              label: item.label,
                                              readonly: "",
                                            },
                                            on: {
                                              "click:clear": function ($event) {
                                                _vm.fields[item.name] = null
                                              },
                                              change: function ($event) {
                                                return _vm.checkForValidationOrFunction(
                                                  item,
                                                  _vm.fields[item.name],
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.fields[item.name],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fields,
                                                  item.name,
                                                  $$v
                                                )
                                              },
                                              expression: "fields[item.name]",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.date_menu,
                              callback: function ($$v) {
                                _vm.$set(item, "date_menu", $$v)
                              },
                              expression: "item.date_menu",
                            },
                          },
                          [
                            _c("v-date-picker", {
                              on: {
                                input: function ($event) {
                                  ;(_vm.fields[item.name] = _vm.parseDate(
                                    item.date_value
                                  )),
                                    (item.date_menu = false)
                                },
                              },
                              model: {
                                value: item.date_value,
                                callback: function ($$v) {
                                  _vm.$set(item, "date_value", $$v)
                                },
                                expression: "item.date_value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 7
                    ? [
                        item &&
                        item.default_value &&
                        !item.default_value.panelId
                          ? [
                              _c(
                                "v-menu",
                                {
                                  ref: "time_menu_" + index,
                                  refInFor: true,
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value": _vm.fields[item.name],
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      return _vm.$set(
                                        _vm.fields,
                                        item.name,
                                        $event
                                      )
                                    },
                                    "update:return-value": function ($event) {
                                      return _vm.$set(
                                        _vm.fields,
                                        item.name,
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    id: "_" + item.name,
                                                    dense: "",
                                                    outlined: "",
                                                    "hide-details": "",
                                                    rules:
                                                      _vm.getValidation(
                                                        item.validation
                                                      ) || [],
                                                    placeholder:
                                                      item.placeholder,
                                                    clearable: "",
                                                    "prepend-icon": "mdi-timer",
                                                    label: item.label,
                                                    readonly: "",
                                                  },
                                                  on: {
                                                    "click:clear": function (
                                                      $event
                                                    ) {
                                                      _vm.fields[item.name] =
                                                        null
                                                    },
                                                    change: function ($event) {
                                                      return _vm.checkForValidationOrFunction(
                                                        item,
                                                        _vm.fields[item.name],
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields[item.name],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fields,
                                                        item.name,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields[item.name]",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: item.time_menu,
                                    callback: function ($$v) {
                                      _vm.$set(item, "time_menu", $$v)
                                    },
                                    expression: "item.time_menu",
                                  },
                                },
                                [
                                  item.time_menu
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          format: "24hr",
                                          "full-width": "",
                                        },
                                        on: {
                                          "click:minute": function ($event) {
                                            _vm.$refs[
                                              "time_menu_" + index
                                            ][0].save(_vm.fields[item.name])
                                          },
                                        },
                                        model: {
                                          value: _vm.fields[item.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, item.name, $$v)
                                          },
                                          expression: "fields[item.name]",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "v-menu",
                                {
                                  ref: "time_menu_" + index,
                                  refInFor: true,
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value": _vm.fields[item.name],
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      return _vm.$set(
                                        _vm.fields,
                                        item.name,
                                        $event
                                      )
                                    },
                                    "update:return-value": function ($event) {
                                      return _vm.$set(
                                        _vm.fields,
                                        item.name,
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    id: "_" + item.name,
                                                    dense: "",
                                                    outlined: "",
                                                    "hide-details": "",
                                                    rules:
                                                      _vm.getValidation(
                                                        item.validation
                                                      ) || [],
                                                    placeholder:
                                                      item.placeholder,
                                                    clearable: "",
                                                    "prepend-icon": "mdi-timer",
                                                    label: item.label,
                                                    readonly: "",
                                                  },
                                                  on: {
                                                    "click:clear": function (
                                                      $event
                                                    ) {
                                                      _vm.fields[item.name] =
                                                        null
                                                    },
                                                    change: function ($event) {
                                                      return _vm.checkForValidationOrFunction(
                                                        item,
                                                        _vm.fields[item.name],
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields[item.name],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fields,
                                                        item.name,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields[item.name]",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.menuTime,
                                    callback: function ($$v) {
                                      _vm.menuTime = $$v
                                    },
                                    expression: "menuTime",
                                  },
                                },
                                [
                                  _vm.menuTime
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          format: "24hr",
                                          "full-width": "",
                                        },
                                        on: {
                                          "click:minute": function ($event) {
                                            _vm.$refs[
                                              "time_menu_" + index
                                            ][0].save(_vm.fields[item.name])
                                          },
                                        },
                                        model: {
                                          value: _vm.fields[item.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, item.name, $$v)
                                          },
                                          expression: "fields[item.name]",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                      ]
                    : _vm._e(),
                  item.type === 8
                    ? [
                        _c("v-switch", {
                          attrs: {
                            dense: "",
                            inset: "",
                            label: item.label,
                            rules: _vm.getValidation(item.validation) || [],
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.fields[item.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.fields, item.name, $$v)
                            },
                            expression: "fields[item.name]",
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.type === 9
                    ? [
                        _vm.isIos
                          ? _c("input", {
                              staticClass: "mx-1",
                              attrs: {
                                type: "file",
                                "prepend-icon": "mdi-camera",
                                label: item.label,
                                multiple: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleFileUpload(
                                    $event,
                                    item._id,
                                    item.name
                                  )
                                },
                              },
                            })
                          : _c("v-file-input", {
                              attrs: {
                                outlined: "",
                                label: item.label,
                                multiple: "",
                                rules: _vm.getValidation(item.validation) || [],
                                "hide-details": "",
                                placeholder: item.placeholder,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.uploadDocumentHandler(
                                    item._id,
                                    item.name,
                                    _vm.fields[item.name]["attachments"]
                                  )
                                },
                              },
                              model: {
                                value: _vm.fields[item.name]["attachments"],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.fields[item.name],
                                    "attachments",
                                    $$v
                                  )
                                },
                                expression: "fields[item.name]['attachments']",
                              },
                            }),
                        _c(
                          "v-row",
                          { key: _vm.reRender, staticClass: "mx-0 ml-8" },
                          _vm._l(
                            _vm.listOfDocuments[item.name],
                            function (document, docIndex) {
                              return _c(
                                "v-col",
                                {
                                  key: document._id,
                                  staticClass: "pa-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pa-0 ma-0 my-auto mr-3",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(document.file_original_name)
                                          ),
                                        ]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "info" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadDocument(
                                                document._id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-scale-transition",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-cloud-download"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "error" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteDocument(
                                                document._id,
                                                item.name
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-scale-transition",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete-circle"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.listOfDocuments[item.name].length >
                                  docIndex + 1
                                    ? _c("v-divider")
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 10
                    ? [
                        _c(
                          "v-card",
                          { staticClass: "pa-0", attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "pa-1 subtitle-1" },
                              [
                                _vm._v(_vm._s(item.label) + " "),
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "ml-2 mt-6",
                                    attrs: {
                                      id: "_" + item.name,
                                      dense: "",
                                      row: "",
                                    },
                                    model: {
                                      value: _vm.fields[item.name]["answer"],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.fields[item.name],
                                          "answer",
                                          $$v
                                        )
                                      },
                                      expression: "fields[item.name]['answer']",
                                    },
                                  },
                                  _vm._l(
                                    _vm.statusList,
                                    function (item, index) {
                                      return _c("v-radio", {
                                        key: index,
                                        attrs: {
                                          label: item.text,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-card-text",
                              { staticClass: "pa-0" },
                              [
                                _c(
                                  "v-tabs",
                                  [
                                    _c("v-tabs-slider"),
                                    _c(
                                      "v-tab",
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-comment-text-outline"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("message.common.comments")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tab",
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mdi-rotate-270" },
                                          [_vm._v("mdi-attachment")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "message.mixed.attachments"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "primary ml-2",
                                            attrs: {
                                              left: "",
                                              for: "",
                                              size: "25",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "white--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.listOfDocuments[
                                                      item.name
                                                    ] &&
                                                      _vm.listOfDocuments[
                                                        item.name
                                                      ].length > 0
                                                      ? _vm.listOfDocuments[
                                                          item.name
                                                        ].length
                                                      : "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("v-divider"),
                                        _c(
                                          "v-card",
                                          { attrs: { flat: "", tile: "" } },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    dense: "",
                                                    "hide-details": "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields[item.name][
                                                        "comments"
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fields[item.name],
                                                        "comments",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields[item.name]['comments']",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("v-divider"),
                                        _c(
                                          "v-card",
                                          {
                                            key: _vm.reRender,
                                            attrs: { flat: "", tile: "" },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                        },
                                                      },
                                                      [
                                                        _vm.isIos
                                                          ? _c("input", {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                type: "file",
                                                                "prepend-icon":
                                                                  "mdi-camera",
                                                                label:
                                                                  item.label,
                                                                multiple: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleFileUpload(
                                                                      $event,
                                                                      item._id,
                                                                      item.name
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _c("v-file-input", {
                                                              attrs: {
                                                                label:
                                                                  "Documents",
                                                                "hide-details":
                                                                  "",
                                                                multiple: "",
                                                                outlined: "",
                                                                dense: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.uploadDocumentHandler(
                                                                      item._id,
                                                                      item.name,
                                                                      _vm
                                                                        .fields[
                                                                        item
                                                                          .name
                                                                      ][
                                                                        "attachments"
                                                                      ]
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.fields[
                                                                    item.name
                                                                  ][
                                                                    "attachments"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .fields[
                                                                        item
                                                                          .name
                                                                      ],
                                                                      "attachments",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "fields[item.name]['attachments']",
                                                              },
                                                            }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  _vm._l(
                                                    _vm.listOfDocuments[
                                                      item.name
                                                    ],
                                                    function (document) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: document._id,
                                                          staticClass:
                                                            "pa-0 pl-2 mt-1",
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "1",
                                                            md: "2",
                                                            sm: "3",
                                                          },
                                                        },
                                                        [
                                                          _c("v-hover", {
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var hover =
                                                                      ref.hover
                                                                    return [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              elevation:
                                                                                hover
                                                                                  ? 14
                                                                                  : 1,
                                                                              shaped:
                                                                                "",
                                                                              flat: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "text-center pa-1",
                                                                            },
                                                                            [
                                                                              document.imgObj &&
                                                                              document
                                                                                .imgObj
                                                                                .url
                                                                                ? [
                                                                                    _c(
                                                                                      "v-img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            contain:
                                                                                              "",
                                                                                            height:
                                                                                              "50",
                                                                                            "lazy-src":
                                                                                              document
                                                                                                .imgObj
                                                                                                .url,
                                                                                            src: document
                                                                                              .imgObj
                                                                                              .url,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : _c(
                                                                                    "v-avatar",
                                                                                    {
                                                                                      staticClass:
                                                                                        "profile",
                                                                                      attrs:
                                                                                        {
                                                                                          size: "52",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              size: 50,
                                                                                              color:
                                                                                                document
                                                                                                  .imgObj
                                                                                                  .color,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              document
                                                                                                .imgObj
                                                                                                .icon
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "v-card-subtitle",
                                                                            {
                                                                              staticClass:
                                                                                "pa-1 text-center text-truncate",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      bottom:
                                                                                        "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "activator",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            return [
                                                                                              _c(
                                                                                                "span",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "font-weight-bold",
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      document.file_original_name
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          document.file_original_name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-divider"
                                                                          ),
                                                                          _c(
                                                                            "v-card-actions",
                                                                            {
                                                                              staticClass:
                                                                                "pa-0",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-spacer"
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.editDocument(
                                                                                          document,
                                                                                          item.name
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-scale-transition",
                                                                                    [
                                                                                      hover
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-lead-pencil"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      color:
                                                                                        "info",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.downloadDocument(
                                                                                          document._id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-scale-transition",
                                                                                    [
                                                                                      hover
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-cloud-download"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.deleteDocument(
                                                                                          document._id,
                                                                                          item.name,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-scale-transition",
                                                                                    [
                                                                                      hover
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-delete-circle"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 11
                    ? [
                        item.label
                          ? _c("v-subheader", { staticClass: "pa-0" }, [
                              _c("b", [_vm._v(_vm._s(item.label) + ":")]),
                            ])
                          : _vm._e(),
                        _c("v-divider"),
                      ]
                    : _vm._e(),
                  item.type === 12
                    ? [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "d-flex align-content-start flex-wrap  pa-0",
                            attrs: { flat: "", tile: "" },
                          },
                          _vm._l(
                            item.default_value.grid_data.labels,
                            function (grid, gIndex) {
                              return _c(
                                "v-card",
                                {
                                  key: gIndex,
                                  staticClass:
                                    "pa-0 pl-1 text-left text-truncate",
                                  attrs: {
                                    "min-width": "80",
                                    "max-width": "80",
                                    title: grid.label,
                                    outlined: "",
                                    tile: "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "subtitle-2" }, [
                                    _vm._v(_vm._s(grid.label)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _vm._l(
                          item.default_value.grid_data.data,
                          function (row, rIndex) {
                            return _c(
                              "v-card",
                              {
                                key: rIndex,
                                staticClass:
                                  "d-flex align-content-start flex-wrap  pa-0",
                                attrs: { flat: "", tile: "" },
                              },
                              _vm._l(row, function (grid, dIndex) {
                                return _c(
                                  "v-card",
                                  {
                                    key: dIndex,
                                    staticClass: "pa-0",
                                    attrs: {
                                      "max-width": "80",
                                      outlined: "",
                                      tile: "",
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "single-line": "",
                                        dense: "",
                                        flat: "",
                                        "full-width": "",
                                        solo: "",
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: grid.value,
                                        callback: function ($$v) {
                                          _vm.$set(grid, "value", $$v)
                                        },
                                        expression: "grid.value",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          }
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              fab: "",
                              dark: "",
                              small: "",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addnewLineOnGrid(
                                  index,
                                  item.label,
                                  item
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 13
                    ? [
                        item.label
                          ? _c("v-subheader", { staticClass: "pa-0" }, [
                              _c("b", [_vm._v(_vm._s(item.label) + ":")]),
                            ])
                          : _vm._e(),
                        _c("VueSignaturePad", {
                          ref: "signaturePad_" + item._id,
                          refInFor: true,
                          staticClass: "signature--pad",
                          attrs: {
                            width: "100%",
                            height:
                              _vm.$formatter.$_setSignaturePadHeight(item) +
                              "px",
                          },
                        }),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              color: "info",
                                              fab: "",
                                              "x-small": "",
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clearThisSignature(
                                                  item._id
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-cached")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Reset")])]
                        ),
                      ]
                    : _vm._e(),
                  item.type === 14
                    ? [
                        _c(
                          "v-card",
                          { staticClass: "pa-1" },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "pa-1 caption font-weight-bold" },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            ),
                            _c("v-divider"),
                            _c(
                              "v-card-text",
                              { staticClass: "pa-1" },
                              [
                                _c("form-render", {
                                  ref: "formReference",
                                  refInFor: true,
                                  attrs: {
                                    aliasFieldsList: item.panel_fields,
                                    listOfDocuments: _vm.listOfDocuments,
                                    id: "panel_" + item._id,
                                    fields: _vm.fields,
                                    checklistId: _vm.checklistId,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 15
                    ? [
                        _c(
                          "span",
                          {
                            staticClass: "pa-0 subtitle-2",
                            style: {
                              fontSize:
                                item.default_value.font_size + "em !important",
                            },
                          },
                          [_vm._v(_vm._s(item.label))]
                        ),
                      ]
                    : _vm._e(),
                  item.type === 16
                    ? [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(item.default_value.html_content),
                          },
                        }),
                      ]
                    : _vm._e(),
                  item.type === 17
                    ? [
                        _c("div", { staticClass: "proptable" }, [
                          _c(
                            "table",
                            {
                              staticClass: "dense_table",
                              attrs: { width: "100%", border: "0" },
                            },
                            [
                              _c(
                                "thead",
                                _vm._l(
                                  item.default_value.grid_header.labels,
                                  function (grid, index) {
                                    return _c(
                                      "th",
                                      { key: index, attrs: { width: "20%" } },
                                      [_vm._v(" " + _vm._s(grid.label) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "tbody",
                                _vm._l(
                                  item.default_value.grid_data.values,
                                  function (row, rIndex) {
                                    return _c(
                                      "tr",
                                      { key: rIndex },
                                      _vm._l(
                                        item.default_value.grid_header.labels,
                                        function (grid, dIndex) {
                                          return _c(
                                            "td",
                                            {
                                              key: dIndex,
                                              style:
                                                dIndex === 0
                                                  ? "padding:0px"
                                                  : "padding:0px;border: none",
                                              attrs: { width: "20%" },
                                            },
                                            [
                                              dIndex === 0
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.name) +
                                                        " "
                                                    ),
                                                  ]
                                                : [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: {
                                                            width: "60%",
                                                          },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              dense: "",
                                                              flat: "",
                                                              "full-width": "",
                                                              solo: "",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            model: {
                                                              value:
                                                                row[
                                                                  "compValue_" +
                                                                    dIndex
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "compValue_" +
                                                                      dIndex,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row[`compValue_${dIndex}`]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass: "pa-1",
                                                          attrs: {
                                                            width: "30%",
                                                          },
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dense: "",
                                                              flat: "",
                                                              "full-width": "",
                                                              "hide-details":
                                                                "",
                                                              items:
                                                                _vm.inventCompItem,
                                                            },
                                                            model: {
                                                              value:
                                                                row[
                                                                  "compSelect_" +
                                                                    dIndex
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "compSelect_" +
                                                                      dIndex,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row[`compSelect_${dIndex}`]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  item.type === 18
                    ? [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-1",
                                attrs: { cols: "12", lg: "5", md: "6" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "float-start",
                                    attrs: {
                                      small: "",
                                      color: "grey darken-3",
                                      dark: "",
                                    },
                                  },
                                  [_vm._v("mdi-information")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mx-2 pb-4 font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.common.dragAndDropTips")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-1",
                                attrs: { cols: "12", lg: "5", md: "6" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "float-start",
                                    attrs: {
                                      small: "",
                                      color: "grey darken-3",
                                      dark: "",
                                    },
                                  },
                                  [_vm._v("mdi-information")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "mx-2 pb-4 font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.common.afterCopyFilter")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        [
                          _c(
                            "v-expansion-panels",
                            { staticClass: "my-2" },
                            _vm._l(
                              item.gird_filter_data,
                              function (gridlist, idx) {
                                return _c(
                                  "v-expansion-panel",
                                  { key: idx },
                                  [
                                    gridlist === item.name
                                      ? _c(
                                          "v-expansion-panel-header",
                                          {
                                            staticClass: "white--text",
                                            attrs: { color: "primary" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.checklist.apply"
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  gridlist
                                                    .toString()
                                                    .split("_")[0].length
                                                    ? gridlist
                                                        .toString()
                                                        .split("_")[0]
                                                    : gridlist
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.checklist.filter"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-expansion-panel-content",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "py-1",
                                            attrs: { outlined: "" },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "py-1" },
                                              [
                                                _c(
                                                  "v-form",
                                                  {
                                                    ref: "filterForm_" + index,
                                                    refInFor: true,
                                                    attrs: {
                                                      "lazy-validation": "",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.gird_filter_data
                                                      .filter,
                                                    function (
                                                      filteList,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "v-row",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            "no-gutters": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-1",
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                class: {
                                                                  "data-grid-filter-field":
                                                                    filteList.searchField,
                                                                },
                                                                attrs: {
                                                                  rules:
                                                                    _vm.filterRules,
                                                                  dense: "",
                                                                  items:
                                                                    item.default_value.grid_data.newLable.filter(
                                                                      function (
                                                                        y
                                                                      ) {
                                                                        return !item.gird_filter_data.filter
                                                                          .map(
                                                                            function (
                                                                              z
                                                                            ) {
                                                                              return z.searchField
                                                                            }
                                                                          )
                                                                          .includes(
                                                                            y.label
                                                                          )
                                                                      }
                                                                    ),
                                                                  placeholder:
                                                                    filteList.searchField
                                                                      ? filteList.searchField
                                                                      : "Field",
                                                                  "item-text":
                                                                    function (
                                                                      label
                                                                    ) {
                                                                      return label &&
                                                                        label.field
                                                                        ? label.label
                                                                        : false
                                                                    },
                                                                  "item-value":
                                                                    function (
                                                                      label
                                                                    ) {
                                                                      return label &&
                                                                        label.field &&
                                                                        label
                                                                          .field
                                                                          .type !==
                                                                          "label"
                                                                        ? label.label
                                                                        : ""
                                                                    },
                                                                  "hide-details":
                                                                    "auto",
                                                                  outlined: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.removeFilters = true
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    filteList.searchField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        filteList,
                                                                        "searchField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "filteList.searchField",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "py-1",
                                                              attrs: {
                                                                cols: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    rules:
                                                                      _vm.filterRules,
                                                                    label:
                                                                      "Value",
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "auto",
                                                                    outlined:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      filteList.searchValue,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          filteList,
                                                                          "searchValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "filteList.searchValue",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "text-center mt-2",
                                                              attrs: {
                                                                cols: "1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "error rounded-xl",
                                                                  attrs: {
                                                                    fab: "",
                                                                    "x-small":
                                                                      "",
                                                                    elevation:
                                                                      "0",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFilter(
                                                                          item
                                                                            .gird_filter_data
                                                                            .filter,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        dark: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-minus"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            item.default_value.grid_data.labels
                                              .filter(function (x) {
                                                return x.field.type !== "label"
                                              })
                                              .filter(function (y) {
                                                return !item.gird_filter_data.filter
                                                  .map(function (z) {
                                                    return z.searchField
                                                  })
                                                  .includes(y.label)
                                              }).length
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "my-2 mx-2",
                                                    attrs: {
                                                      fab: "",
                                                      dark: "",
                                                      "x-small": "",
                                                      color: "green",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addnewFiler(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          dark: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [_vm._v("mdi-plus")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-actions",
                                              { staticClass: "mx-auto" },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "py-0",
                                                        attrs: { cols: "1" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "float-start",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "grey darken-3",
                                                                                  dark: "",
                                                                                },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("ol", [
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.filterSaveResult"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_1"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_2"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_3"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_4"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_5"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_6"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "message.common.datagrid_filter_help_text_7"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center float-center align-center justify-center py-0",
                                                        attrs: { cols: "11" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "info",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.searchFilter(
                                                                  item,
                                                                  index,
                                                                  false
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "message.checklist.filter"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "mx-2",
                                                            attrs: {
                                                              color:
                                                                "error lighten-2",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clearDataGridFilter(
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "message.shifts.clear"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        item.default_value &&
                        item.default_value.grid_data &&
                        item.default_value.grid_data.labels
                          ? _c(
                              "v-simple-table",
                              {
                                key: _vm.key,
                                staticClass:
                                  "bordered--table datagrid-18-table",
                              },
                              [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    _vm._l(
                                      item.default_value.grid_data.labels,
                                      function (grid, index) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            style:
                                              "height:34px; min-width:" +
                                              grid.pixels +
                                              "px",
                                          },
                                          [_vm._v(_vm._s(grid.label))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      item.default_value.grid_data.filterData,
                                      function (
                                        trData,
                                        trIndex,
                                        rowIndexValue
                                      ) {
                                        return [
                                          trData
                                            ? _c(
                                                "tr",
                                                {
                                                  key: trIndex,
                                                  ref: "dataGrid_" + item.name,
                                                  refInFor: true,
                                                  attrs: { draggable: true },
                                                  on: {
                                                    dragstart: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleDrag(
                                                        $event,
                                                        trData,
                                                        trIndex,
                                                        index,
                                                        rowIndexValue,
                                                        item.default_value
                                                          .grid_data.filterData
                                                      )
                                                    },
                                                    dragover: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                    },
                                                    drop: function ($event) {
                                                      return _vm.handleDrop(
                                                        $event,
                                                        trData,
                                                        trIndex,
                                                        index,
                                                        rowIndexValue,
                                                        item.default_value
                                                          .grid_data.filterData
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    item.default_value.grid_data
                                                      .labels,
                                                    function (tdGrid, tdIndex) {
                                                      return _c(
                                                        "td",
                                                        { key: tdIndex },
                                                        [
                                                          trData[tdIndex] &&
                                                          (trData[tdIndex]
                                                            .conditionscript &&
                                                          trData[tdIndex]
                                                            .isShowElement
                                                            ? trData[tdIndex]
                                                                .isShowElement
                                                              ? trData[
                                                                  tdIndex
                                                                ].isShowElement(
                                                                  trData[
                                                                    tdIndex
                                                                  ],
                                                                  _vm.self
                                                                )
                                                              : function () {}
                                                            : trData[tdIndex]
                                                                .isShow)
                                                            ? [
                                                                tdGrid.field
                                                                  .type ===
                                                                  _vm
                                                                    .fieldTypeConstants
                                                                    .STRING ||
                                                                tdGrid.field
                                                                  .type ===
                                                                  _vm
                                                                    .fieldTypeConstants
                                                                    .NUMBER
                                                                  ? [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id:
                                                                                "_" +
                                                                                tdGrid.name +
                                                                                "_" +
                                                                                trIndex,
                                                                              placeholder:
                                                                                tdGrid
                                                                                  .field
                                                                                  .type ===
                                                                                _vm
                                                                                  .fieldTypeConstants
                                                                                  .STRING
                                                                                  ? "Text"
                                                                                  : "Number",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              type:
                                                                                tdGrid
                                                                                  .field
                                                                                  .type ===
                                                                                _vm
                                                                                  .fieldTypeConstants
                                                                                  .NUMBER
                                                                                  ? "number"
                                                                                  : "text",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.checkShowHideFunctionOnChange(
                                                                                  trData[
                                                                                    tdIndex
                                                                                  ],
                                                                                  trData[
                                                                                    tdIndex
                                                                                  ]
                                                                                    .value,
                                                                                  trIndex
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                trData[
                                                                                  tdIndex
                                                                                ]
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ],
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "trData[tdIndex].value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                _vm
                                                                  .fieldTypeConstants
                                                                  .TEXTAREA
                                                                  ? [
                                                                      _c(
                                                                        "v-textarea",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              rows: "3",
                                                                              id:
                                                                                "_" +
                                                                                tdGrid.name +
                                                                                "_" +
                                                                                trIndex,
                                                                              placeholder:
                                                                                "Textarea",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.checkShowHideFunctionOnChange(
                                                                                  trData[
                                                                                    tdIndex
                                                                                  ],
                                                                                  trData[
                                                                                    tdIndex
                                                                                  ]
                                                                                    .value,
                                                                                  trIndex
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                trData[
                                                                                  tdIndex
                                                                                ]
                                                                                  .value,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ],
                                                                                    "value",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "trData[tdIndex].value",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                  _vm
                                                                    .fieldTypeConstants
                                                                    .CHECKBOX &&
                                                                tdGrid.field
                                                                  .typedata
                                                                  ? [
                                                                      tdGrid
                                                                        .field
                                                                        .typedata
                                                                        .listItems
                                                                        ? _c(
                                                                            "v-layout",
                                                                            {
                                                                              staticClass:
                                                                                "col",
                                                                              attrs:
                                                                                {
                                                                                  wrap: "",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              tdGrid
                                                                                .field
                                                                                .typedata
                                                                                .listItems,
                                                                              function (
                                                                                options,
                                                                                index
                                                                              ) {
                                                                                return _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    key: index,
                                                                                    staticClass:
                                                                                      "mt-0  pa-1 mx-1",
                                                                                    attrs:
                                                                                      {
                                                                                        id:
                                                                                          "_" +
                                                                                          tdGrid.name +
                                                                                          "_" +
                                                                                          trIndex,
                                                                                        "hide-details":
                                                                                          "",
                                                                                        label:
                                                                                          options.label,
                                                                                        value:
                                                                                          options.value,
                                                                                        multiple:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.checkShowHideFunctionOnChange(
                                                                                            trData[
                                                                                              tdIndex
                                                                                            ],
                                                                                            trData[
                                                                                              tdIndex
                                                                                            ]
                                                                                              .value,
                                                                                            trIndex,
                                                                                            item,
                                                                                            tdIndex
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          trData[
                                                                                            tdIndex
                                                                                          ]
                                                                                            .value,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              trData[
                                                                                                tdIndex
                                                                                              ],
                                                                                              "value",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "trData[tdIndex].value",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                  _vm
                                                                    .fieldTypeConstants
                                                                    .RADIOBOX &&
                                                                tdGrid.field
                                                                  .typedata
                                                                  ? [
                                                                      tdGrid
                                                                        .field
                                                                        .typedata
                                                                        .listItems
                                                                        ? _c(
                                                                            "v-row",
                                                                            {
                                                                              staticClass:
                                                                                "mx-0",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-radio-group",
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-0",
                                                                                  attrs:
                                                                                    {
                                                                                      id:
                                                                                        "_" +
                                                                                        tdGrid.name +
                                                                                        "_" +
                                                                                        trIndex,
                                                                                      row: "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.checkShowHideFunctionOnChange(
                                                                                          trData[
                                                                                            tdIndex
                                                                                          ],
                                                                                          trData[
                                                                                            tdIndex
                                                                                          ]
                                                                                            .value,
                                                                                          trIndex,
                                                                                          item,
                                                                                          tdIndex
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        trData[
                                                                                          tdIndex
                                                                                        ]
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            trData[
                                                                                              tdIndex
                                                                                            ],
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "trData[tdIndex].value",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  tdGrid
                                                                                    .field
                                                                                    .typedata
                                                                                    .listItems,
                                                                                  function (
                                                                                    options,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-radio",
                                                                                      {
                                                                                        key: index,
                                                                                        attrs:
                                                                                          {
                                                                                            label:
                                                                                              options.label,
                                                                                            value:
                                                                                              options.value,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.handleRadioButtonClickforRadio(
                                                                                                item,
                                                                                                trData,
                                                                                                trIndex,
                                                                                                tdGrid,
                                                                                                tdIndex,
                                                                                                options
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                  _vm
                                                                    .fieldTypeConstants
                                                                    .SELECT &&
                                                                tdGrid.field
                                                                  .typedata
                                                                  ? [
                                                                      (
                                                                        tdGrid
                                                                          .field
                                                                          .typedata
                                                                          .selectbox_type !==
                                                                        undefined
                                                                          ? tdGrid
                                                                              .field
                                                                              .typedata
                                                                              .selectbox_type ===
                                                                            "default"
                                                                          : true
                                                                      )
                                                                        ? _c(
                                                                            "v-autocomplete",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id:
                                                                                    "_" +
                                                                                    tdGrid.name +
                                                                                    "_" +
                                                                                    trIndex,
                                                                                  dense:
                                                                                    "",
                                                                                  items:
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .listItems,
                                                                                  multiple:
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .isMultiSelect,
                                                                                  clearable:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                  "item-text":
                                                                                    "label",
                                                                                  "item-value":
                                                                                    "value",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.checkShowHideFunctionOnChange(
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ],
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ]
                                                                                        .value,
                                                                                      trIndex
                                                                                    )
                                                                                  },
                                                                              },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "selection",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var item =
                                                                                          ref.item
                                                                                        var index =
                                                                                          ref.index
                                                                                        return [
                                                                                          index ===
                                                                                          0
                                                                                            ? _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item.label
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          index ===
                                                                                          1
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "grey--text caption",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "(+" +
                                                                                                      _vm._s(
                                                                                                        trData[
                                                                                                          tdIndex
                                                                                                        ]
                                                                                                          .value
                                                                                                          .length -
                                                                                                          1
                                                                                                      ) +
                                                                                                      " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "message.common.others"
                                                                                                        )
                                                                                                      ) +
                                                                                                      ")"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ]
                                                                                      .value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        trData[
                                                                                          tdIndex
                                                                                        ],
                                                                                        "value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "trData[tdIndex].value",
                                                                                },
                                                                            }
                                                                          )
                                                                        : tdGrid
                                                                            .field
                                                                            .typedata
                                                                            .selectbox_type ===
                                                                          "custom"
                                                                        ? _c(
                                                                            "v-autocomplete",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id:
                                                                                    "_" +
                                                                                    tdGrid.name +
                                                                                    "_" +
                                                                                    trIndex,
                                                                                  dense:
                                                                                    "",
                                                                                  "item-text":
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .optionName,
                                                                                  "item-value":
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .optionValue,
                                                                                  items:
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .customOptions,
                                                                                  multiple:
                                                                                    tdGrid
                                                                                      .field
                                                                                      .typedata
                                                                                      .isMultiSelect,
                                                                                  clearable:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.checkShowHideFunctionOnChange(
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ],
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ]
                                                                                        .value,
                                                                                      trIndex
                                                                                    )
                                                                                  },
                                                                              },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "selection",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var item =
                                                                                          ref.item
                                                                                        var index =
                                                                                          ref.index
                                                                                        return [
                                                                                          index ===
                                                                                          0
                                                                                            ? _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item[
                                                                                                        tdGrid
                                                                                                          .field
                                                                                                          .typedata
                                                                                                          .optionName
                                                                                                      ]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          index ===
                                                                                          1
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "grey--text caption",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "(+" +
                                                                                                      _vm._s(
                                                                                                        trData[
                                                                                                          tdIndex
                                                                                                        ]
                                                                                                          .value
                                                                                                          .length -
                                                                                                          1
                                                                                                      ) +
                                                                                                      " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "message.common.others"
                                                                                                        )
                                                                                                      ) +
                                                                                                      ")"
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ]
                                                                                      .value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        trData[
                                                                                          tdIndex
                                                                                        ],
                                                                                        "value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "trData[tdIndex].value",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                _vm
                                                                  .fieldTypeConstants
                                                                  .LABEL
                                                                  ? [
                                                                      tdGrid
                                                                        .field
                                                                        .typedata
                                                                        ? _c(
                                                                            "label",
                                                                            [
                                                                              tdGrid
                                                                                .field
                                                                                .typedata
                                                                                .calculatingValue &&
                                                                              tdGrid
                                                                                .field
                                                                                .typedata
                                                                                .insertPosition ===
                                                                                "prefix"
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          +tdGrid
                                                                                            .field
                                                                                            .typedata
                                                                                            .calculatingValue *
                                                                                            (+rowIndexValue +
                                                                                              1)
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  tdGrid
                                                                                    .field
                                                                                    .typedata
                                                                                    .labeltext
                                                                                )
                                                                              ),
                                                                              tdGrid
                                                                                .field
                                                                                .typedata
                                                                                .calculatingValue &&
                                                                              tdGrid
                                                                                .field
                                                                                .typedata
                                                                                .insertPosition ===
                                                                                "suffix"
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          +tdGrid
                                                                                            .field
                                                                                            .typedata
                                                                                            .calculatingValue *
                                                                                            (+rowIndexValue +
                                                                                              1)
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  : _vm._e(),
                                                                tdGrid.field
                                                                  .type ===
                                                                _vm
                                                                  .fieldTypeConstants
                                                                  .DATE
                                                                  ? [
                                                                      _c(
                                                                        "v-menu",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "close-on-content-click": false,
                                                                              "max-width":
                                                                                "290",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activator",
                                                                                  fn: function (
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                readonly:
                                                                                                  "",
                                                                                                id:
                                                                                                  "_" +
                                                                                                  tdGrid.name +
                                                                                                  "_" +
                                                                                                  trIndex,
                                                                                                dense:
                                                                                                  "",
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                clearable:
                                                                                                  "",
                                                                                                "prepend-icon":
                                                                                                  "mdi-calendar",
                                                                                              },
                                                                                            on: {
                                                                                              "click:clear":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  trData[
                                                                                                    tdIndex
                                                                                                  ].value =
                                                                                                    null
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  trData[
                                                                                                    tdIndex
                                                                                                  ]
                                                                                                    .value,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      trData[
                                                                                                        tdIndex
                                                                                                      ],
                                                                                                      "value",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "trData[tdIndex].value",
                                                                                              },
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                          model:
                                                                            {
                                                                              value:
                                                                                trData[
                                                                                  tdIndex
                                                                                ]
                                                                                  .date_menu,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ],
                                                                                    "date_menu",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "trData[tdIndex].date_menu",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary darken-2",
                                                                                  "header-color":
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.triggerDatePickerChangeEvent(
                                                                                      "_" +
                                                                                        tdGrid.name +
                                                                                        "_" +
                                                                                        trIndex
                                                                                    )
                                                                                    _vm.checkShowHideFunctionOnChange(
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ],
                                                                                      trData[
                                                                                        tdIndex
                                                                                      ]
                                                                                        .value,
                                                                                      trIndex
                                                                                    )
                                                                                  },
                                                                                input:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    ;(trData[
                                                                                      tdIndex
                                                                                    ].value =
                                                                                      _vm.parseDate(
                                                                                        trData[
                                                                                          tdIndex
                                                                                        ]
                                                                                          .date_value
                                                                                      )),
                                                                                      (trData[
                                                                                        tdIndex
                                                                                      ].date_menu = false)
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    trData[
                                                                                      tdIndex
                                                                                    ]
                                                                                      .date_value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        trData[
                                                                                          tdIndex
                                                                                        ],
                                                                                        "date_value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "trData[tdIndex].date_value",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "error rounded-xl",
                                                          attrs: {
                                                            fab: "",
                                                            "x-small": "",
                                                            elevation: "0",
                                                            title: _vm.$t(
                                                              "message.common.delete_row"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeGrid(
                                                                item
                                                                  .default_value
                                                                  .grid_data
                                                                  .filterData,
                                                                trData,
                                                                trIndex,
                                                                rowIndexValue,
                                                                item
                                                                  .default_value
                                                                  .grid_data,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                dark: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-minus"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                    item.showSum.showSumRow &&
                                    !_vm.$formatter.isEmptyObject(
                                      item.default_value.grid_data.data
                                    )
                                      ? _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              background: "lightgrey",
                                            },
                                          },
                                          _vm._l(
                                            item.default_value.grid_data.labels,
                                            function (sumRow, sumIndex) {
                                              return _c(
                                                "th",
                                                {
                                                  key: sumIndex,
                                                  staticStyle: {
                                                    height: "30px",
                                                  },
                                                },
                                                [
                                                  item.showSum.sumColIndexes.includes(
                                                    sumIndex
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size":
                                                              "0.9rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getGridDataValuesTotal(
                                                                sumIndex,
                                                                item
                                                                  .default_value
                                                                  .grid_data
                                                                  .data
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              fab: "",
                              dark: "",
                              "x-small": "",
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addnewLineOnGrid(
                                  index,
                                  item.label,
                                  item
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.type === 19
                    ? [
                        item.html
                          ? _c(
                              "v-card",
                              {
                                staticClass: "component-link",
                                attrs: { outlined: "" },
                              },
                              [
                                _c("v-card-text", { staticClass: "pa-2" }, [
                                  _c("p", {
                                    domProps: { innerHTML: _vm._s(item.html) },
                                  }),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card",
                          {
                            staticClass: "d-flex flex-row",
                            attrs: { flat: "" },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  outlined: "",
                                  width: "100%",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "mx-0",
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "cols",
                                        staticStyle: {
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              disabled:
                                                (item.default_value
                                                  .hideComments &&
                                                  item.default_value
                                                    .hideAttachments) ||
                                                _vm.hasCommentOrAttachment(
                                                  _vm.fields[item.name],
                                                  item
                                                ),
                                              "x-small": "",
                                              fab: "",
                                              text: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                item.expandsurveysection =
                                                  !item.expandsurveysection
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  "" +
                                                    (item.expandsurveysection
                                                      ? "mdi-arrow-up"
                                                      : "mdi-arrow-down")
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mx-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass: "pt-0 pa-1 subtitle-1",
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mb-2",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        item.default_value.surveyOptions &&
                                        item.default_value.surveyOptions
                                          .selectionType === 1
                                          ? [
                                              _vm.fields[item.name]
                                                ? _c(
                                                    "v-layout",
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.default_value.options
                                                        .filter(function (x) {
                                                          return x.isactive
                                                        })
                                                        .filter(function (x) {
                                                          return x.isactive
                                                        }),
                                                      function (option, index) {
                                                        return _c(
                                                          "v-checkbox",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              label:
                                                                option.label,
                                                              value:
                                                                option.value,
                                                              multiple: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.fields[
                                                                  item.name
                                                                ].answer,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.fields[
                                                                      item.name
                                                                    ],
                                                                    "answer",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "fields[item.name].answer",
                                                            },
                                                          }
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          : [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: {
                                                    "hide-details": "",
                                                    row: "",
                                                    id: "_" + item.name,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields[item.name]
                                                        .answer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fields[item.name],
                                                        "answer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields[item.name].answer",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.getActiveOptions(
                                                    item.default_value
                                                  ),
                                                  function (options, index) {
                                                    return _c("v-radio", {
                                                      key: index,
                                                      staticClass: "pa-0 ma-0",
                                                      attrs: {
                                                        label: options.label,
                                                        value: options.value,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRadioButtonClick(
                                                            item,
                                                            options,
                                                            _vm.fields[
                                                              item.name
                                                            ].answer,
                                                            "answer"
                                                          )
                                                        },
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                    item.default_value.checkboxFlags
                                      .signature &&
                                    !item.fieldsToHide.includes("signature")
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1 my-1",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                id:
                                                  "_" +
                                                  item.name +
                                                  "_signature",
                                                outlined: "",
                                                "hide-details": "",
                                                label: _vm.$t(
                                                  "message.mixed.signature"
                                                ),
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.fields[item.name]
                                                    .signature,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.fields[item.name],
                                                    "signature",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fields[item.name].signature",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.default_value.checkboxFlags.date &&
                                    !item.fieldsToHide.includes("date")
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-1 my-1",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "close-on-content-click": false,
                                                  "max-width": "290",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  dense: "",
                                                                  outlined: "",
                                                                  "hide-details":
                                                                    "",
                                                                  clearable: "",
                                                                  "prepend-inner-icon":
                                                                    "mdi-calendar",
                                                                  label: _vm.$t(
                                                                    "message.common.date"
                                                                  ),
                                                                },
                                                                on: {
                                                                  "click:clear":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.fields[
                                                                        item.name
                                                                      ].date =
                                                                        null
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.fields[
                                                                      item.name
                                                                    ].date,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fields[
                                                                          item
                                                                            .name
                                                                        ],
                                                                        "date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "fields[item.name].date",
                                                                },
                                                              },
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.fields[item.name]
                                                      .date_menu,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.fields[item.name],
                                                      "date_menu",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fields[item.name].date_menu",
                                                },
                                              },
                                              [
                                                _c("v-date-picker", {
                                                  on: {
                                                    input: function ($event) {
                                                      ;(_vm.fields[
                                                        item.name
                                                      ].date_menu = false),
                                                        (_vm.fields[
                                                          item.name
                                                        ].date = _vm.parseDate(
                                                          _vm.fields[item.name]
                                                            .date_value
                                                        ))
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields[item.name]
                                                        .date_value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fields[item.name],
                                                        "date_value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields[item.name].date_value",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-expand-transition",
                                  [
                                    item.expandsurveysection
                                      ? _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      (!item.default_value
                                                        .hideComments &&
                                                        !item.fieldsToHide.includes(
                                                          "comments"
                                                        )) ||
                                                      (!item.default_value
                                                        .hideAttachments &&
                                                        !item.fieldsToHide.includes(
                                                          "attachments"
                                                        )) ||
                                                      item.html,
                                                    expression:
                                                      "((!item.default_value.hideComments && !item.fieldsToHide.includes('comments')) || (!item.default_value.hideAttachments && !item.fieldsToHide.includes('attachments'))) || item.html",
                                                  },
                                                ],
                                                staticClass: "pa-0",
                                              },
                                              [
                                                _c(
                                                  "v-tabs",
                                                  [
                                                    _c("v-tabs-slider"),
                                                    !item.default_value
                                                      .hideComments &&
                                                    !item.fieldsToHide.includes(
                                                      "comments"
                                                    )
                                                      ? _c(
                                                          "v-tab",
                                                          {
                                                            staticClass: "pa-0",
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-comment-text-outline"
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "message.common.comments"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !item.default_value
                                                      .hideAttachments &&
                                                    !item.fieldsToHide.includes(
                                                      "attachments"
                                                    )
                                                      ? _c(
                                                          "v-tab",
                                                          {
                                                            staticClass: "pa-0",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mdi-rotate-270",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-attachment"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "message.mixed.attachments"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                staticClass:
                                                                  "primary ml-2",
                                                                attrs: {
                                                                  left: "",
                                                                  for: "",
                                                                  size: "25",
                                                                },
                                                              },
                                                              [
                                                                _vm
                                                                  .listOfDocuments[
                                                                  item.name
                                                                ]
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "white--text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .listOfDocuments[
                                                                              item
                                                                                .name
                                                                            ]
                                                                              .length
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "white--text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "0"
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !item.default_value
                                                      .hideComments &&
                                                    !item.fieldsToHide.includes(
                                                      "comments"
                                                    )
                                                      ? _c(
                                                          "v-tab-item",
                                                          [
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "ma-0 pa-0",
                                                            }),
                                                            _c(
                                                              "v-card",
                                                              {
                                                                attrs: {
                                                                  flat: "",
                                                                  tile: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card-text",
                                                                  [
                                                                    _c(
                                                                      "v-textarea",
                                                                      {
                                                                        attrs: {
                                                                          "hide-details":
                                                                            "",
                                                                          outlined:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .fields[
                                                                              item
                                                                                .name
                                                                            ][
                                                                              "comments"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .fields[
                                                                                  item
                                                                                    .name
                                                                                ],
                                                                                "comments",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "fields[item.name]['comments']",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !item.default_value
                                                      .hideAttachments &&
                                                    !item.fieldsToHide.includes(
                                                      "attachments"
                                                    )
                                                      ? _c(
                                                          "v-tab-item",
                                                          [
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "ma-0 pa-0",
                                                            }),
                                                            _c(
                                                              "v-card",
                                                              {
                                                                key: _vm.reRender,
                                                                attrs: {
                                                                  flat: "",
                                                                  tile: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card-text",
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "mx-0 pa-0",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0",
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                                md: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm.isIos
                                                                              ? _c(
                                                                                  "input",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mx-1",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "file",
                                                                                        "prepend-icon":
                                                                                          "mdi-camera",
                                                                                        label:
                                                                                          item.label,
                                                                                        multiple:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.handleFileUpload(
                                                                                            $event,
                                                                                            item._id,
                                                                                            item.name
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "v-file-input",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          _vm.$t(
                                                                                            "message.shifts.documents"
                                                                                          ),
                                                                                        "hide-details":
                                                                                          "",
                                                                                        multiple:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                        dense:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.uploadDocumentHandler(
                                                                                            item._id,
                                                                                            item.name,
                                                                                            _vm
                                                                                              .fields[
                                                                                              item
                                                                                                .name
                                                                                            ][
                                                                                              "attachments"
                                                                                            ]
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .fields[
                                                                                            item
                                                                                              .name
                                                                                          ][
                                                                                            "attachments"
                                                                                          ],
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm
                                                                                                .fields[
                                                                                                item
                                                                                                  .name
                                                                                              ],
                                                                                              "attachments",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "fields[item.name]['attachments']",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        key: _vm.reRender,
                                                                        staticClass:
                                                                          "mx-0 ml-8",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .listOfDocuments[
                                                                          item
                                                                            .name
                                                                        ],
                                                                        function (
                                                                          document,
                                                                          docIndex
                                                                        ) {
                                                                          return _c(
                                                                            "v-col",
                                                                            {
                                                                              key: document._id,
                                                                              staticClass:
                                                                                "pa-0",
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                [
                                                                                  _c(
                                                                                    "p",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pa-0 ma-0 my-auto mr-3",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          document.file_original_name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-spacer"
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          color:
                                                                                            "primary",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.editDocument(
                                                                                              document,
                                                                                              item.name
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-scale-transition",
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-lead-pencil"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          color:
                                                                                            "info",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.downloadDocument(
                                                                                              document._id
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-scale-transition",
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-cloud-download"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          color:
                                                                                            "error",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteDocument(
                                                                                              document._id,
                                                                                              item.name
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-scale-transition",
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-delete-circle"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm
                                                                                .listOfDocuments[
                                                                                item
                                                                                  .name
                                                                              ]
                                                                                .length >
                                                                              docIndex +
                                                                                1
                                                                                ? _c(
                                                                                    "v-divider"
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                    false
                                                                      ? _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "no-gutters":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm
                                                                              .listOfDocuments[
                                                                              item
                                                                                .name
                                                                            ],
                                                                            function (
                                                                              document
                                                                            ) {
                                                                              return _c(
                                                                                "v-col",
                                                                                {
                                                                                  key: document._id,
                                                                                  staticClass:
                                                                                    "pa-0 pl-2 mt-1",
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "12",
                                                                                      lg: "1",
                                                                                      md: "2",
                                                                                      sm: "3",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-hover",
                                                                                    {
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "default",
                                                                                              fn: function (
                                                                                                ref
                                                                                              ) {
                                                                                                var hover =
                                                                                                  ref.hover
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-card",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          elevation:
                                                                                                            hover
                                                                                                              ? 14
                                                                                                              : 1,
                                                                                                          shaped:
                                                                                                            "",
                                                                                                          flat: "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-card-text",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-center pa-1",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm.mimeTypeOfAttachment(
                                                                                                            document
                                                                                                          )
                                                                                                            .url
                                                                                                            ? [
                                                                                                                _c(
                                                                                                                  "v-img",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        contain:
                                                                                                                          "",
                                                                                                                        height:
                                                                                                                          "50",
                                                                                                                        "lazy-src":
                                                                                                                          _vm.mimeTypeOfAttachment(
                                                                                                                            document
                                                                                                                          )
                                                                                                                            .url,
                                                                                                                        src: _vm.mimeTypeOfAttachment(
                                                                                                                          document
                                                                                                                        )
                                                                                                                          .url,
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ]
                                                                                                            : _c(
                                                                                                                "v-avatar",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "profile",
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      size: "52",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-icon",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          size: 50,
                                                                                                                          color:
                                                                                                                            _vm.mimeTypeOfAttachment(
                                                                                                                              document
                                                                                                                            )
                                                                                                                              .color,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          _vm.mimeTypeOfAttachment(
                                                                                                                            document
                                                                                                                          )
                                                                                                                            .icon
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                        ],
                                                                                                        2
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-card-subtitle",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "pa-1 text-center text-truncate",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-tooltip",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  bottom:
                                                                                                                    "",
                                                                                                                },
                                                                                                              scopedSlots:
                                                                                                                _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key: "activator",
                                                                                                                      fn: function (
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var on =
                                                                                                                          ref.on
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            _vm._g(
                                                                                                                              {
                                                                                                                                staticClass:
                                                                                                                                  "font-weight-bold",
                                                                                                                              },
                                                                                                                              on
                                                                                                                            ),
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  document.file_original_name
                                                                                                                                )
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      },
                                                                                                                    },
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                ),
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    _vm._s(
                                                                                                                      document.file_original_name
                                                                                                                    )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "v-card-actions",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "pa-0",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-spacer"
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  small:
                                                                                                                    "",
                                                                                                                  icon: "",
                                                                                                                  color:
                                                                                                                    "primary",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.editDocument(
                                                                                                                      document,
                                                                                                                      item.name
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-scale-transition",
                                                                                                                [
                                                                                                                  hover
                                                                                                                    ? _c(
                                                                                                                        "v-icon",
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "mdi-lead-pencil"
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  small:
                                                                                                                    "",
                                                                                                                  icon: "",
                                                                                                                  color:
                                                                                                                    "info",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.downloadDocument(
                                                                                                                      document.file_original_name
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-scale-transition",
                                                                                                                [
                                                                                                                  hover
                                                                                                                    ? _c(
                                                                                                                        "v-icon",
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "mdi-cloud-download"
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "v-btn",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  small:
                                                                                                                    "",
                                                                                                                  icon: "",
                                                                                                                  color:
                                                                                                                    "error",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.deleteDocument(
                                                                                                                      document._id,
                                                                                                                      item.name
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-scale-transition",
                                                                                                                [
                                                                                                                  hover
                                                                                                                    ? _c(
                                                                                                                        "v-icon",
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "mdi-delete-circle"
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.updateFileDialog,
            callback: function ($$v) {
              _vm.updateFileDialog = $$v
            },
            expression: "updateFileDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                { ref: "updateAttachment" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "headline grey lighten-2 pa-2",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("message.common.update")) +
                          " " +
                          _vm._s(_vm.$t("message.deviation.document"))
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-2" },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("message.deviation.document")) + ": "
                        ),
                        _c("b", [
                          _vm._v(_vm._s(_vm.updateDocument.file_original_name)),
                        ]),
                      ]),
                      _c("v-file-input", {
                        staticClass: "mt-2",
                        attrs: { label: "Document", outlined: "", dense: "" },
                        model: {
                          value: _vm.updateFilePicker,
                          callback: function ($$v) {
                            _vm.updateFilePicker = $$v
                          },
                          expression: "updateFilePicker",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.fileLoader },
                          on: { click: _vm.updateDocumentHandler },
                        },
                        [_vm._v(_vm._s(_vm.$t("message.common.update")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              ;(_vm.updateFileDialog = false),
                                _vm.$refs.updateAttachment.reset()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }